import { Component, Input, OnInit } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-doctor-hospital-list',
  templateUrl: './doctor-hospital-list.page.html',
  styleUrls: ['./doctor-hospital-list.page.scss'],
})
export class DoctorHospitalListPage implements OnInit {

  @Input() docHospList;

  constructor(private popoverCtrl: PopoverController, private toastCtrl: ToastController) { }

  ngOnInit() {
    console.log("hosp", this.docHospList);
  }

  async selectedHospital(hl) {    
    let toast = this.toastCtrl.create({
      message: "Your Profile switched to "+hl.hosprofile.hospitalName,
      duration: 3000,
      position: 'bottom'
    });

    (await toast).present();
    this.popoverCtrl.dismiss(hl);
  }

}
