import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-showeditbillcomment',
  templateUrl: './showeditbillcomment.page.html',
  styleUrls: ['./showeditbillcomment.page.scss'],
})
export class ShoweditbillcommentPage implements OnInit {

  @Input() public obj:any;

  constructor() { }

  ngOnInit() {
    console.log("comments",this.obj);
  }

}
