import { Component, OnInit } from '@angular/core';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { PopoverController } from '@ionic/angular';
import { UploadDownloadService } from '../upload-download.service';

@Component({
  selector: 'app-chat-room-file-upload-page',
  templateUrl: './chat-room-file-upload-page.page.html',
  styleUrls: ['./chat-room-file-upload-page.page.scss'],
})
export class ChatRoomFileUploadPagePage implements OnInit {
  uploadFile="";

  constructor(private fileOpener: FileOpener, private popoverCtrl: PopoverController, private updownLoad: UploadDownloadService) { }

  ngOnInit() {
  }

  fileChange(event: any) {
    console.log("file event==>", event);
    let res = this.updownLoad.uploadKeyGenerate(event);
    console.log("key generated status==>", res);
    if (res == "success") {
      console.log("key generated name==>", this.updownLoad.generatedKeyName);
      let dismissObj:any={
        uploadFile: this.updownLoad.uploadfile,
        keyName: this.updownLoad.generatedKeyName,
        fileName:this.updownLoad.fileName
      }
      this.popoverCtrl.dismiss(dismissObj);
    }
  }
}
