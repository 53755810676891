import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy} from '@angular/router';

import {JWT_OPTIONS, JwtModule} from '@auth0/angular-jwt';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {Storage, IonicStorageModule} from "@ionic/storage-angular";
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {HttpClientModule} from "@angular/common/http";
import { HttpModule } from '@angular/http';
import { CommonModule, DatePipe } from "@angular/common";
import { File } from '@ionic-native/file/ngx';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { AutoCompleteModule } from 'ionic4-auto-complete';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import {WebSocketService} from './services/web-socket.service';
// import { CameraPreview } from '@ionic-native/camera-preview/ngx';
// import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import {Camera} from '@ionic-native/camera/ngx';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment.prod';
import { ChatAudioRecordingService } from '../app/services/chatAudioRecording.service';
import { FilterPipeModule } from 'ngx-filter-pipe';
// import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';

// Agora Video Call Import
import { NgxAgoraModule, AgoraConfig } from 'ngx-agora';



import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { FilePath } from '@ionic-native/file-path/ngx';
// Firebase Push Notification Plugin
// import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';

export function jwtOptionsFactory(storage: Storage) {
  return {  
    tokenGetter: () => storage.get('jwt_token'),
    whitelistedDomains: ['localhost:8080']
  }
}

// Agora APP Id Config
const agoraConfig: AgoraConfig = {
  AppID: '9574c9a0ff9d41aa842ef6cc89e2904f',
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    HttpClientModule,
    HttpModule,
    CommonModule, 
    IonicStorageModule.forRoot(),
    NgxAgoraModule.forRoot(agoraConfig), //Initialize Agora 
    AngularFireModule.initializeApp(environment.firebaseConfig),
   AngularFireDatabaseModule,
    JwtModule.forRoot({ 
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Storage]
      }
    }),
    FilterPipeModule,
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DatePipe,
    AutoCompleteModule,
    // CameraPreview,
    Camera,
    File,
    FilePath,
    FileOpener,
    ChatAudioRecordingService,
    InAppBrowser,
    WebSocketService,
    FilePath
    // SplashScreen
    // FirebaseX
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
