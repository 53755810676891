import { Component, Input, OnInit } from '@angular/core';
import { ModulesApisService } from '../services/modules-apis.service';

@Component({
  selector: 'app-bank-detail-view',
  templateUrl: './bank-detail-view.page.html',
  styleUrls: ['./bank-detail-view.page.scss'],
})
export class BankDetailViewPage implements OnInit {
  bankDetail:any;
  @Input() docDetail:any;

  constructor(private modulesApis: ModulesApisService) { }

  ngOnInit() {
  }

  ionViewWillEnter() {    
    console.log("init call--dd>", this.docDetail);
    console.log("doctor detail", this.docDetail);
    this.modulesApis.getDocBankDetail(this.docDetail.docUserID)
    .subscribe(prodata => {
      if(prodata != null) {
        console.log("bank detail", prodata);
        this.bankDetail = prodata;
      }       
    });
  }

}
