import { Component, OnInit, Input } from '@angular/core';
import { AlertController, LoadingController, ToastController, ModalController, NavController,NavParams } from '@ionic/angular';
import { ModulesApisService } from '../services/modules-apis.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.page.html',
  styleUrls: ['./change-password.page.scss'],
})
export class ChangePasswordPage implements OnInit {

  @Input() public docDetail:any;
  @Input() public userType:any;

  userdetail:any;
  ConfirmPassword=true;
  userID:any;

  constructor(public navCtrl: NavController, public navParams: NavParams, public loadingCtrl: LoadingController,
    public authProvider:ModulesApisService, private readonly toastCtrl: ToastController,
    private readonly alertCtrl: AlertController,
    private authService: AuthenticationService,
    private modalCtrl:ModalController) { 
      
    }

  ngOnInit() {
    this.userdetail=this.docDetail;
      console.log(this.userdetail);
      console.log(this.userType);
      //this.userType=navParams.get('userType');      
      switch(this.userType){
        case'Patient':
          this.userID=this.userdetail.userID;
          break;
        case'Doctor':
          this.userID=this.userdetail.docUserID;
          break;
        case'Nurse':
          this.userID=this.userdetail.nurseUserID;
          break;
        case'Receptionist':
          this.userID=this.userdetail.recpUserID;
          break;
        case'PharmacyUser':
          this.userID=this.userdetail.pharUserID;
          break;
        case'DXUser':
          this.userID=this.userdetail.dxUserID;
          break;
          
      }
  }
  checkOldPassword(value: any)
  {
    let login={
    	user_id:this.userID,
    	password:value
    }       
    
    this.authProvider
    .deactivatelogin(login)
    .subscribe(
      (data) => {        
        if(data=='NOTEXISTS') {
          this.showSuccesToast(data);
        }else{
          console.log("old password success");
        }                  
      },
      err => {
        console.log(err);
        this.handleError(err);
      });
  }

  changePassword(old:any, value: any, value1:any) {
    // console.log("ddede...",value);
    // console.log("ddede1...",value1);
    if(old!='' && value!='' && value1!=''){
      if(old==value1)
      {
        alert("New password can't be the same as old password");
      }
      else if(value!==value1) {
        this.ConfirmPassword=false;  
        //console.log("failed");    
      }
      else{
        this.ConfirmPassword=true;       
        
        let login={
          user_id:this.userID,
          password:old
        }
        
        
        this.authProvider
        .deactivatelogin(login)
        .subscribe(
        (data) => {          
        if(data=='NOTEXISTS')
        {
          this.showSuccesToast(data);
        }
        else
        {
          // change password
          this.authProvider
          .changePassAllUser(value1, this.userID)
          .subscribe(data => {
            //console.log("res.....",data);
            let res:any=data;
            if(res.response=="success"){
              alert("Password changed successfully");
              this.modalCtrl.dismiss("success");
              this.logout();
            }
          },
          err => {
            console.log(err);
            this.handleError1(err);
          });
        }
            
        },
        err => {
          console.log(err);
          this.handleError(err);
        });       
          
      }
    }else{
      alert("Please fill all the fields");
    }
  }

  private showSuccesToast(data: string) {
    alert(data);
  }

  handleError(error: any) {
    let message: string;
    if (error.status && error.status === 401) {
      
    }
    else {
      message = `Unexpected error: ${error.statusText}`;
    }

    /* const toast = this.toastCtrl.create({
      message,
      duration: 2000,
      position: 'bottom'
    });

    toast.present(); */
  }

  handleError1(error: any) {
   
  }

  checkNewPassword (value:string,value1:string)
  {
    if(value!=value1) {
      this.ConfirmPassword=false;      
    }
    else{
      this.ConfirmPassword=true;      
    }
  }

  goBack(){
    this.modalCtrl.dismiss("cancel");
  }

  logout() {
    this.authService.logout();
  }

}
