import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModulesApisService } from '../services/modules-apis.service';

@Component({
  selector: 'app-view-bill-edit-history',
  templateUrl: './view-bill-edit-history.page.html',
  styleUrls: ['./view-bill-edit-history.page.scss'],
})
export class ViewBillEditHistoryPage implements OnInit {

  @Input() public billDetail: any;
  @Input() public type: any;

  billEditList:any=[];

  initData: any = {
    page: "billHistory",
  };

  constructor(private moduleApi: ModulesApisService, private modalCtrl: ModalController) { 

  }

  ngOnInit() {
    this.initData.tenantID = this.billDetail.bill.tenantID;  
    console.log("bill", this.billDetail);
    this.moduleApi
    .getBillEditHistory(this.billDetail.bill.billingID)
    .subscribe(data=>{
      console.log("Data", data);
      this.billEditList=data;
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
