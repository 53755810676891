import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-followup-complete-chatpop',
  templateUrl: './followup-complete-chatpop.page.html',
  styleUrls: ['./followup-complete-chatpop.page.scss'],
})
export class FollowupCompleteChatpopPage implements OnInit {

  constructor(private popoverCtrl: PopoverController) { }

  ngOnInit() {
  }

  back() {
    this.popoverCtrl.dismiss("no");
  }

  proceed() {
    this.popoverCtrl.dismiss("yes");
  }

}
