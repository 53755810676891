import { Component, OnInit,Input } from '@angular/core';
import { ModalController,Platform } from '@ionic/angular';

@Component({
  selector: 'app-viewpatientaddress',
  templateUrl: './viewpatientaddress.page.html',
  styleUrls: ['./viewpatientaddress.page.scss'],
})
export class ViewpatientaddressPage implements OnInit {

  @Input() public patientAddress: any;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
  }

  close()
    {
      this.modalCtrl.dismiss("closed");
    }

}
