export const SWASTHCONFIG  = {

    HEADER_LOGO : "https://swasth.online/logo-swasth-icon.svg",
    SITE_URL : "https://swasth.online/",
    // S3PRESCRIPTION_URL : "https://swasthprescription.s3-ap-southeast-1.amazonaws.com/",

    S3PRESCRIPTION_URL : "https://document.swasth.online/",

    //Medicine Qty Images
    FULL_TABLET : "https://swasth.online/icon-tablet-full.png",
    HALF_TABLET : "https://swasth.online/icon-tablet-half.png",

    //Image URLS
    S3BASE_URL : "https://document.swasth.online/", 
    // RAZORPAYMODE:"Test",
    RAZORPAYMODE:"Live",


    //Server SSL
    SWASTHPLUS: "https://api.swasth.online/flappt",
    SERVER_URL : "https://api.swasth.online/auth",
    PATIENT_URL : "https://api.swasth.online/flpatient",
    DOCTOR_URL : "https://api.swasth.online/fldoctor",
    NURSE_URL : "https://api.swasth.online/flnurse", 
    DX_URL : "https://api.swasth.online/dxcenter",
    PHARMACY_URL : "https://api.swasth.online/pharmacy",
    MEDICINE_URL : "https://api.swasth.online/prescription",
    LOOKUP_URL:"https://api.swasth.online/circlelookup",
    VITAL_URL : "https://api.swasth.online/flvital",
    RECP_URL : "https://api.swasth.online/flrecp",
    PRESC_TEMPLATE : "https://api.swasth.online/presctemplate",
    SEND_MEDICINE : "https://api.swasth.online/pharsend",
    SEND_DXCENTER : "https://api.swasth.online/dxsend",
    SEND_NURSE : "https://api.swasth.online/sendnurse",
    SEND_DOCTOR : "https://api.swasth.online/senddoctor", 
    UPLOAD_URL : "https://api.swasth.online/circleupload",
    DOWNLOAD_URL : "https://api.swasth.online/circledownload",
    HOSPITAL_URL:"https://api.swasth.online/flhospital", 
    Bill_URL:"https://api.swasth.online/flbill", 
    FOLLOWUP_URL:"https://api.swasth.online/followup",
    PAYMENT_URL : "https://api.swasth.online/payment",
    BILL_URL:"https://api.swasth.online/flbill",
    APPOINTMENT_URL : "https://api.swasth.online/flappt", 
    PRESCRIPTION_URL : "https://api.swasth.online/flprescription",
    SUPPORT_URL: "https://api.swasth.online/flsupport",
    CHATGPT_URL: "https://api.swasth.online/python",
      


    //Local Room
    // SWASTHPLUS: "http://localhost:5080/flappt",
    // SERVER_URL : " http://localhost:5052/auth",
    // PATIENT_URL : "http://localhost:5080/flpatient",
    // DOCTOR_URL : "http://localhost:5080/fldoctor",
    // HOSPITAL_URL : "http://localhost:5080/flhospital",
    // NURSE_URL : "http://localhost:5080/flnurse", 
    // DX_URL : "http://localhost:5054/dxcenter",
    // PHARMACY_URL : "http://localhost:5054/pharmacy",
    // PRESCRIPTION_URL : "http://localhost:5080/flprescription",
    // MEDICINE_URL : "http://localhost:5058/prescription",
    // LOOKUP_URL:"http://localhost:5077/circlelookup",
    // VITAL_URL : "http://localhost:5080/flvital",
    // RECP_URL : "http://localhost:5080/flrecp",
    // PRESC_TEMPLATE : "http://localhost:5058/presctemplate",
    // SEND_MEDICINE : "http://localhost:5068/pharsend",
    // SEND_DXCENTER : "http://localhost:5068/dxsend",
    // SEND_NURSE : "http://localhost:5068/sendnurse",
    // SEND_DOCTOR : "http://localhost:5068/senddoctor",
    // APPOINTMENT_URL : "http://localhost:5071/flappt", 
    // UPLOAD_URL : "http://localhost:5077/circleupload",
    // DOWNLOAD_URL : "http://localhost:5077/circledownload",
    // PAYMENT_URL : "http://localhost:5080/payment",
    // BILL_URL:"http://localhost:5080/flbill",
    // FOLLOWUP_URL:"http://localhost:5080/followup",
    // SUPPORT_URL: "http://localhost:5080/flsupport",
    // CHATGPT_URL: "http://localhost:5000/python",


    // Office 

    // SWASTHPLUS: "http://172.16.100.148:5080/flappt",
    // SERVER_URL : " http://172.16.100.148:5052/auth",
    // PATIENT_URL : "http://172.16.100.148:5080/flpatient",
    // DOCTOR_URL : "http://172.16.100.148:5080/fldoctor",
    // HOSPITAL_URL : "http://172.16.100.148:5080/flhospital",
    // NURSE_URL : "http://172.16.100.148:5080/flnurse", 
    // DX_URL : "http://172.16.100.148:5054/dxcenter",
    // PHARMACY_URL : "http://172.16.100.148:5054/pharmacy",
    // PRESCRIPTION_URL : "http://172.16.100.148:5080/flprescription",
    // MEDICINE_URL : "http://172.16.100.148:5058/prescription",
    // LOOKUP_URL:"http://172.16.100.148:5077/circlelookup",
    // VITAL_URL : "http://172.16.100.148:5080/flvital",
    // RECP_URL : "http://172.16.100.148:5080/flrecp",
    // PRESC_TEMPLATE : "http://172.16.100.148:5058/presctemplate",
    // SEND_MEDICINE : "http://172.16.100.148:5068/pharsend",
    // SEND_DXCENTER : "http://172.16.100.148:5068/dxsend",
    // SEND_NURSE : "http://172.16.100.148:5068/sendnurse",
    // SEND_DOCTOR : "http://172.16.100.148:5068/senddoctor",
    // APPOINTMENT_URL : "http://172.16.100.148:5071/flappt", 
    // UPLOAD_URL : "http://172.16.100.148:5077/circleupload",
    // DOWNLOAD_URL : "http://172.16.100.148:5077/circledownload",
    // PAYMENT_URL : "http://172.16.100.148:5080/payment",
    // BILL_URL:"http://172.16.100.148:5080/flbill",
    // FOLLOWUP_URL:"http://172.16.100.148:5080/followup",
    // SUPPORT_URL: "http://172.16.100.148:5080/flsupport",
    // CHATGPT_URL: "http://172.16.100.148:5000/python"

    // SWASTHPLUS: "http://172.16.100.158:5080/flappt",
    // SERVER_URL : " http://172.16.100.158:5052/auth",
    // PATIENT_URL : "http://172.16.100.158:5080/flpatient",
    // DOCTOR_URL : "http://172.16.100.158:5080/fldoctor",
    // HOSPITAL_URL : "http://172.16.100.158:5080/flhospital",
    // NURSE_URL : "http://172.16.100.158:5080/flnurse", 
    // DX_URL : "http://172.16.100.158:5054/dxcenter",
    // PHARMACY_URL : "http://172.16.100.158:5054/pharmacy",
    // PRESCRIPTION_URL : "http://172.16.100.158:5080/flprescription",
    // MEDICINE_URL : "http://172.16.100.158:5058/prescription",
    // LOOKUP_URL:"http://172.16.100.158:5077/circlelookup",
    // VITAL_URL : "http://172.16.100.158:5080/flvital",
    // RECP_URL : "http://172.16.100.158:5080/flrecp",
    // PRESC_TEMPLATE : "http://172.16.100.158:5058/presctemplate",
    // SEND_MEDICINE : "http://172.16.100.158:5068/pharsend",
    // SEND_DXCENTER : "http://172.16.100.158:5068/dxsend",
    // SEND_NURSE : "http://172.16.100.158:5068/sendnurse",
    // SEND_DOCTOR : "http://172.16.100.158:5068/senddoctor",
    // APPOINTMENT_URL : "http://172.16.100.158:5080/flappt", 
    // UPLOAD_URL : "http://172.16.100.158:5077/circleupload",
    // DOWNLOAD_URL : "http://172.16.100.158:5077/circledownload",
    // PAYMENT_URL : "http://172.16.100.158:5080/payment",
    // BILL_URL:"http://172.16.100.158:5080/flbill",
    // FOLLOWUP_URL:"http://172.16.100.148:5080/followup",
    // SUPPORT_URL: "http://172.16.100.158:5080/flsupport",
    // CHATGPT_URL: "https://api.swasth.online/python"
}

