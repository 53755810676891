import { Component, Input, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { popoverController } from '@ionic/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-popover-content-page',
  templateUrl: './popover-content-page.page.html',
  styleUrls: ['./popover-content-page.page.scss'],
})
export class PopoverContentPagePage implements OnInit {

  @Input() public apptDetail: any;
  @Input() public chatDetail: any;
  @Input() public ptDetail: any;

  constructor(private alertCtrl: AlertController,private authService: AuthenticationService,) { }

  ngOnInit() {
    console.log("data",this.chatDetail, this.apptDetail, this.ptDetail);
  }

  async completeChat() {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Complete!',
      mode: "ios",
      message: 'Are you sure want to complete this appointment.',
      buttons: [
        {
          text: 'No',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Yes',
          handler: () => {
            console.log('Confirm Okay');
            this.apptDetail.vchatStatus="completed";
            this.authService
            .updateSwasthVideoAppt(this.apptDetail)
            .subscribe(data=>{
              console.log("data", data);
            });
            this.chatDetail.status="Deactive";
            this.authService
            .completeSwasthVideoAppt(this.chatDetail)
            .subscribe(data=>{
              console.log("data", data);
              if(data=="Success") {
                popoverController.dismiss("Success");
              }
            });
          }
        }
      ]
    });
    await alert.present();
  }
}
