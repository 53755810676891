import { Component, OnInit,Input } from '@angular/core';
import { ModulesApisService } from '../services/modules-apis.service';

@Component({
  selector: 'app-showtreatmentplan',
  templateUrl: './showtreatmentplan.page.html',
  styleUrls: ['./showtreatmentplan.page.scss'],
})
export class ShowtreatmentplanPage implements OnInit {

  @Input() apptID;
  treatmentPlanList:any;

  constructor(private modulesApis: ModulesApisService) { }

  ngOnInit() {
    this.modulesApis.getTreatmentPlanList(this.apptID).subscribe((treatmentPlan:any)=>{
      this.treatmentPlanList=treatmentPlan;
    });
  }

}
