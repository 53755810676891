import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { Storage } from '@ionic/storage';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ModulesApisService } from '../services/modules-apis.service';

const TOKEN_KEY = 'swplus-token';
const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})


export class AuthGuard implements CanActivate {

  token = '';
  expectedRole: any;
  roleLength: any;
  authRole: any;

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(private router: Router, private authService: AuthenticationService,
    private storage: Storage, private modulesApis: ModulesApisService) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    //this.getToken();
    this.roleLength = (route.data.role).split(" ");
    if (this.roleLength.length == 1) {
      this.expectedRole = this.roleLength[0];
      console.log("Role", this.expectedRole);
    }
    return this.authService.isAuthenticated.pipe(
      filter((val) => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map((isAuthenticated) => {
        if (isAuthenticated) {
          if (this.modulesApis.decodedToken != undefined) {
            this.authRole = this.modulesApis.decodedToken.type;
            console.log(this.authRole);
            if (this.authRole != undefined) {
              console.log(this.roleLength.length);
              if (this.roleLength.length > 0) {
                if ((this.roleLength.length == 1 && this.roleLength[0] == this.authRole) || this.roleLength.length > 1) {
                  console.log(this.roleLength[0] == this.authRole);
                  return true;
                }
                else
                {
                  this.unAuthorisedUser();
                }
              }
            }
          }
        } else {
          this.router.navigateByUrl('/login');
          return false;
        }
      })
    );
  }

  // async getToken()
  // {
  // const token = await this.storage.get(TOKEN_KEY);
  // this.token=token;
  // let decodedToken = helper.decodeToken(this.token);
  // console.log('decode token: ', decodedToken);
  // this.authRole=decodedToken.type;
  // }


  // canLoad(): Observable<boolean> {
  //   // let route= this.route;
  //   //   this.roleLength = (route.data.role).split(" ");
  //   // if (this.roleLength.length == 1) {
  //   //   this.expectedRole = this.roleLength[0];
  //   //   console.log("Role",this.expectedRole);
  //   // }
  // 	return this.authService.isAuthenticated.pipe(
  // 		filter((val) => val !== null), // Filter out initial Behaviour subject value
  // 		take(1), // Otherwise the Observable doesn't complete!
  // 		map((isAuthenticated) => {
  // 			if (isAuthenticated) {
  // 				return true;
  // 			} else {
  // 				this.router.navigateByUrl('/login');
  // 				return false;
  // 			}
  // 		})
  // 	);
  // }


  // canActivate(route: ActivatedRouteSnapshot) {
  //   //this.getToken();
  //   this.roleLength = (route.data.role).split(" ");
  //   if (this.roleLength.length == 1) {
  //     this.expectedRole = this.roleLength[0];
  //   }
  //   if (this.modulesApis.decodedToken != undefined) {
  //     this.authRole = this.modulesApis.decodedToken.type;
  //     console.log(this.authRole);
  //     if (this.authRole != undefined) {
  //       console.log(this.roleLength.length);
  //       if (this.roleLength.length > 0) {
  //         if ((this.roleLength.length == 1 && this.roleLength[0] == this.authRole) || this.roleLength.length > 1) {
  //           console.log(this.roleLength[0] == this.authRole);
  //           //this.isAuthenticated.next(true);
  //           return true;

  //           // return this.authService.isAuthenticated.pipe(
  //           //   filter(val => val !== null),
  //           //   take(1),
  //           //   map(isAuthenticated => {
  //           //     console.log("Guard", isAuthenticated);
  //           //     if (isAuthenticated) {
  //           //       return true;
  //           //     } else {
  //           //       this.router.navigateByUrl('/login');
  //           //       return false;
  //           //     }
  //           //   }

  //           //   )
  //           // );
  //         } else {
  //           this.unAuthorisedUser();
  //         }
  //       }
  //       else {
  //         this.unAuthorisedUser();
  //       }
  //     }
  //     else {
  //       this.unAuthorisedUser();
  //     }
  //   }
  //   else {
  //     this.loadTokenFromStorage();
  //   }

  //   // console.log("exceptrole--", this.expectedRole);
  //   // console.log("authrole--", this.authRole);


  // }

  async loadTokenFromStorage() {
    if (this.storage.get(TOKEN_KEY)) {
      console.log(': ', this.storage.get(TOKEN_KEY));
      const token = await this.storage.get(TOKEN_KEY);
      if (token) {
        console.log('set token: ', token);
        this.token = token;
        let decodedToken = helper.decodeToken(this.token);
        this.authRole = decodedToken.type;
        console.log('Role: ', this.authRole);
        if (this.authRole != undefined) {
          if (this.roleLength.length > 0) {
            if ((this.roleLength.length == 1 && this.roleLength[0] == this.authRole) || this.roleLength.length > 1) {
              //this.isAuthenticated.next(true);
              return true;

              // return this.authService.isAuthenticated.pipe(
              //   filter(val => val !== null),
              //   take(1),
              //   map(isAuthenticated => {
              //     console.log("Guard", isAuthenticated);
              //     if (isAuthenticated) {
              //       return true;
              //     } else {
              //       this.router.navigateByUrl('/login');
              //       return false;
              //     }
              //   }

              //   )
              // );
            } else {
              this.unAuthorisedUser();
            }
          }
          else {
            this.unAuthorisedUser();
          }
        }
        else {
          this.unAuthorisedUser();
        }
      }
    }
  }



  unAuthorisedUser() {
    this.expectedRole = "";
    this.authService.logout();
    alert("Un Authorised User");
    this.router.navigateByUrl('/login');
  }
  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }

}

