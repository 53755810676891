import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class SwstoragService {
  private _storage: Storage | null = null;


  constructor(private storage: Storage) { 
    this.storage.create();
  }

  setStorage(key:string,value:any){
    this.storage.set(key, value);
  }

  getStorage(key:string){
    return this.storage.get(key);
  }

  clearStorage(){
    this.storage.clear();
  }

  removeStorage(key:string){
    this.storage.remove(key);
  }

}
