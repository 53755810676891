import { Component, Input, OnInit } from '@angular/core';
import { AlertController, PopoverController } from '@ionic/angular';
import { FollowupCompleteChatpopPage } from '../followup-complete-chatpop/followup-complete-chatpop.page';
import { ModulesApisService } from '../services/modules-apis.service';

@Component({
  selector: 'app-followup-chat-popover',
  templateUrl: './followup-chat-popover.page.html',
  styleUrls: ['./followup-chat-popover.page.scss'],
})
export class FollowupChatPopoverPage implements OnInit {

  @Input() public popoverType: any;
  @Input() public ptPCKR;
  @Input() public senderProfile;
  @Input() public senderRole;

  serverdate: Date;

  constructor(private readonly modulesApis: ModulesApisService, private popoverCtrl: PopoverController, private alertCtrl: AlertController,
    ) { }

  ngOnInit() {
    this.popoverType = '';
  }

  async completeChat() {   
    console.log("ptchat all detail", this.ptPCKR);
    if(this.ptPCKR.ptChat.chatStatus == 'Active') {
      if(this.senderRole == 'Member') {
        if (this.ptPCKR.ptChat.complaintStatus == "Yes") {
          alert("This consultation cannot be completed. Please wait till your patient sends their complaint.")
        } else {
          const popover1 = await this.popoverCtrl.create({
            component: FollowupCompleteChatpopPage,
            translucent: true,
            backdropDismiss: true,
            cssClass: 'completepopover'
          });
      
          popover1.onDidDismiss().then(async data => {
            let dismissValue: any = data;            
          });
          await popover1.present();
        }
      } else {
        this.adminDocPtChatComplete();
      }      
    } else {
      alert("Please wait for your patient to start the consultation");
      this.popoverCtrl.dismiss();
    }
    
  }

  async adminDocPtChatComplete() {
    const popover1 = await this.popoverCtrl.create({
      component: FollowupCompleteChatpopPage,
      translucent: true,
      backdropDismiss: true,
      cssClass: 'completepopover'
    });

    if (this.ptPCKR.chatRestric.status == "Deactive") {
      alert("Please wait for your patient to start the consultation");
    }

    popover1.onDidDismiss().then(async data => {
      console.log("detail", data);
      if(data.data == 'yes') { 
        const alert = await this.alertCtrl.create({
          header: 'Confirm',
          message: 'Are you sure want to reduce the patient complaint request <br><strong> Remaining Consultation: </strong>' + (this.ptPCKR.planChatCount - this.ptPCKR.chatRestric.chatCount),
          buttons: [
            {
              text: 'Yes',
              handler: () => { 
                this.ptPCKR.ptChat.chatStatus = 'Deactive';
                let deactivateChatObj: any = {  
                  docRole: this.senderRole,
                  chatRestricID: this.ptPCKR.chatRestric.chatRestrictionID,
                  ptChatObj: this.ptPCKR.ptChat,
                  chReduceStatus: 'yes',
                  status: 'Deactive'
                }
                this.modulesApis
                .ptChatDeactiveReduceCount(deactivateChatObj)
                .subscribe(dacData => {
                  console.log("data",dacData);
                  this.popoverCtrl.dismiss();
                });
              }
            },
            {
              text: 'No',
              handler: () => {
                console.log('no');
                alert.dismiss();                
              }
            }
          ]
        });
        await alert.present();
      }
      
    });
    await popover1.present();
  }

  ionViewWillEnter() {
    this.modulesApis
      .getServerTime()
      .subscribe(serverDate => {
        let unix_timestamp = parseInt(serverDate);
        this.serverdate = new Date(unix_timestamp);
        console.log(this.serverdate);
      });
  }

}
