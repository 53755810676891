import { Injectable, OnInit, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { webSocket } from "rxjs/webSocket";
import { takeUntil } from "rxjs/operators";
import { Subscription } from "rxjs/Subscription";
// import { io } from "socket.io-client";

@Injectable({
  providedIn: "root",
})
export class WebSocketService implements OnDestroy {
  subject;
  destroy: Subject<boolean> = new Subject<boolean>();
  patientJoind: Subject<any> = new Subject<any>();
  doctorTriage: Subject<any> = new Subject<any>();
  message: Subject<any> = new Subject<any>();
  dashBoard: Subject<any> = new Subject<any>();
  chatcomplete: Subject<any> = new Subject<any>();
  refreshChat: Subject<any> = new Subject<any>();
  apptBooking:Subject<any>=new Subject<any>();
  plusVideoapptBooking:Subject<any>=new Subject<any>();
  waitingVideoapptBooking:Subject<any>=new Subject<any>();
  plusClinicalAppts:Subject<any>=new Subject<any>();
  plusPrescription:Subject<any>=new Subject<any>();
  plusDocSharePresc:Subject<any>=new Subject<any>();
  plusBills:Subject<any>=new Subject<any>();
  plusVitals:Subject<any>=new Subject<any>();
  observableVar: Subscription;
  timeout: any;
  constructor() {

  }

  ngOnDestroy(): void {
    this.subject.complete();
    this.destroy.next(true);
    this.timeout=null;
  }

  subcribe(doctorId, firstTime, userType, tenantid) {
    //alert(doctorId);
    if (firstTime || !this.subject) {
      this.destroy.next(false);      
      this.subject = webSocket(
        // "ws://172.16.100.19:7779/" + doctorId + ","+userType+","+tenantid);
        "http://localhost:7779/" + doctorId + ","+userType+","+tenantid);
        // "wss://plusautorefresh.swasth.online/" + doctorId + ","+userType+","+tenantid);
    }

    this.timeout =  setInterval(() => {
      this.subject.next({"data" : {"type" : "ping","msg" : "ping" } });
    }, 58000);

    //const ws = new WebSocket("ws://localhost:8889/");
    //const ws = new WebSocket("wss://autorefresh.swasth.online/");
    //setWsHeartbeat(ws, 'ping');

    this.subject.onmessage = (msg) => {
      console.log(msg.data)
    }

    


    this.subject.onopen = () => {
      this.subject.send("Hello");  // this fires OK
    }

    this.subject.pipe(takeUntil(this.destroy)).subscribe(
      (data) => {
        if (data) {

          const newData = data;
          console.log("autorefresh data===>",newData);
          if (newData.type == 'joinNotification') {
            console.log("Patient Joined");
            this.patientJoind.next(true);
            if (newData.msg) {
              this.message.next(newData.msg);
            }
            else if (newData.chatStatus) {
              this.chatcomplete.next(newData.chatStatus);
            }
            else if (newData.join) {
              console.log("Patient Joined");
              this.patientJoind.next(true);
              this.refreshChat.next(true);
            }
          }
          else if (newData.type == "triageDoctor") {
            this.doctorTriage.next(true);
          }
          else if (newData.type == "triageDoctorToPatient") {
            this.doctorTriage.next(true);
          }
          else if(newData.type=="appointment")
          {
            console.log("Appointment");
            this.apptBooking.next(newData);
          }
          else if(newData.type=="plusvideoappointment")
          {
            console.log("Plus Video Appointment");
            this.plusVideoapptBooking.next(newData);
          }
          else if(newData.type=="waitingvideoappointment")
          {
            console.log("Waiting Video Appointment");
            this.waitingVideoapptBooking.next(newData);
          }
          else if(newData.type=="plusclinicalappointment")
          {
            console.log("Plus clinical Appointment");
            this.plusClinicalAppts.next(newData);
            if(newData.apptStatus=='Completed') {
              this.plusBills.next(newData);
            }
          }
          else if(newData.type=="plusprescription")
          {
            console.log("Plus prescription creation");
            if(newData.fromPage != 'menu') {
              this.plusPrescription.next(newData);
            } 
            if(newData.shareDocSt==true) {
              console.log("Plus prescription share case");
              this.plusDocSharePresc.next(newData);
            }            
          }
          else if(newData.type=="plusbills") 
          {
            console.log("Plus bills update");
            this.plusBills.next(newData);
            if(newData.fromPage=='updatebill') {
              this.plusClinicalAppts.next(newData);
            }
          }
          else if(newData.type=="plusvitals") 
          {            
            this.plusClinicalAppts.next(newData);
          }
          else if (newData.type == "message") {
          }
        }
        this.dashBoard.next(true);
        console.log(data);
      },      
      (err) => {
        console.log(err);          
      }
    );

  }

  // pingBong()
  // {
  //   console.log("Ping Bong");
  //   this.subject.next();
  //   return "Ping Bong";
  // }

  signDoctor(data) {
    console.log("called");
    this.subject.next(data);
  }

  signNurse(data) {
    console.log("called");
    this.subject.next(data);
  }

  signReceptionist(data) {
    console.log("called");
    this.subject.next(data);
  }

  triageDoctor(doctorId, circleCode) {
    this.subject.next({ "userId": doctorId, "data": { "type": "triageDoctor", "circleCode": circleCode } });
  }

  triageDoctorToPatient(patientId, circleCode) {
    this.subject.next({ "userId": patientId, "data": { "type": "triageDoctorToPatient", "circleCode": circleCode } });

  }

  sendMessageToPatient(patientId, msg) {
    this.subject.next({ "sentBy": "doctor", "userId": patientId, "data": { "type": "joinNotification", "msg": msg } });

  }

  sendConfirmationToPatient(patientId, circleCode) {
    this.subject.next({ "sentBy": "doctor", "userId": patientId, "data": { "type": "joinNotification", "circleCode": circleCode } });

  }

  sendChatCompleteToPatient(patientId, circleCode, chatstatus) {
    this.subject.next({ "sentBy": "doctor", "userId": patientId, "data": { "type": "joinNotification", "circleCode": circleCode, "chatStatus": chatstatus } });
  }

  sendPresNotificationToPatient(patientId,status,circleCode,docUserId) {
    this.subject.next({ "userId": patientId, "data": { "type": "pres","status":status,"ptuid":patientId,"circlecode":circleCode,"docuserid":docUserId } });

  }

  JoinCircleNotification() {
    return this.patientJoind;
  }

  sendVideoCallNotificationToPatient(doctoruid,patientuid,circlecode,type)
  {
    console.log(doctoruid,patientuid,circlecode,type);
    this.subject.next({"sentBy":"doctor","userId":patientuid,"data":{"type":"video","docuid":doctoruid,"ptuid":patientuid,"circlecode":circlecode,"calltype":type}})
  }

  sendAppointmentCompletedNotificationToPatient(patientuid,doctoruid,apptdate,appttime,circlecode)
  {
    this.subject.next({"sentBy" : 'doctor',"userId" : patientuid,"data" : {"type" : "appointment","doctoruid" : doctoruid,"apptdate":apptdate,"appttime":appttime,"circlecode":circlecode,"apptType":"completed"} });
  }

  sendPlusVideoAppointmentCompletedNotificationToPatient(patientuid,doctoruid,apptdate,appttime,circlecode)
  {
    this.subject.next({"sentBy" : 'doctor',"userId" : patientuid,"data" : {"type" : "plusvideoappointment","doctoruid" : doctoruid,"apptdate":apptdate,"appttime":appttime,"circlecode":circlecode,"plusVideoapptType":"completed"} });
  }
  sendWaitingVideoAppointmentCompletedNotificationToPatient(patientuid,doctoruid,circlecode)
  {
    this.subject.next({"sentBy" : 'doctor',"userId" : patientuid,"data" : {"type" : "waitingvideoappointment","doctoruid" : doctoruid,"circlecode":circlecode,"waitingVideoapptType":"completed"} });
  }

  plusBookClinicalAppt(usertype,patientuid,doctoruid,apptdate,appttime, fromid, tenantID, apptStatus) {                                                              
    this.subject.next({"sentBy" : usertype,"userId" : doctoruid,"data" : {"type" : "plusclinicalappointment","doctoruid" : doctoruid,"apptdate":apptdate,"appttime":appttime, "fromid": fromid, "tenantID":tenantID, "apptStatus":apptStatus} });   
  }

  plusPrescDirectFromAppt(usertype,ptuid,ptuhid,doctoruid,fromid, tenantID, fromPage, shareDocStatus, shareToDoc) {                                                              
    this.subject.next({"sentBy" : usertype,"userId" : doctoruid,"data" : {"type" : "plusprescription","doctoruid" : doctoruid,"ptuid":ptuid,"ptuhid":ptuhid, "fromid": fromid, "tenantID":tenantID, "fromPage": fromPage, "shareDocSt":shareDocStatus, "shareToDocID":shareToDoc} });   
  }

  plusBillUpdates(usertype,ptuid,ptuhid,doctoruid,fromid, tenantID, fromPage) {                                                              
    this.subject.next({"sentBy" : usertype,"userId" : doctoruid,"data" : {"type" : "plusbills","doctoruid" : doctoruid,"ptuid":ptuid,"ptuhid":ptuhid, "fromid": fromid, "tenantID":tenantID, "fromPage": fromPage} });   
  }

  plusVitalsUpdates(usertype,ptuid,ptuhid,doctoruid,fromid, tenantID, fromPage) {                                                              
    this.subject.next({"sentBy" : usertype,"userId" : doctoruid,"data" : {"type" : "plusvitals","doctoruid" : doctoruid,"ptuid":ptuid,"ptuhid":ptuhid, "fromid": fromid, "tenantID":tenantID, "fromPage": fromPage} });   
  }
               
}