import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AutoCompleteService } from 'ionic4-auto-complete';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SWASTHCONFIG } from 'src/config';
import { SWASTH_CONST } from '../constant';

@Injectable({
  providedIn: 'root'
})
export class PatientAcService implements AutoCompleteService{

  tenantID:string;

  constructor(public http: HttpClient) {
    console.log('patient autocomplete service');
   }

   getResults(keyword:string){

    console.log("Tenant ID=>>",this.tenantID);
  	return this.http.get(`${SWASTHCONFIG.PATIENT_URL}/patientsearchbyname/`+keyword+'/'+this.tenantID)
  	.pipe(map((result:any[]) =>
    {
      return result             
      // .filter(item => item.ptFirstName.toLowerCase().startsWith(keyword.toLowerCase()));   
      .filter(item => item.ptMobileNo);        
    }))
    .pipe(
      catchError((err) => {
        console.log("err");
        SWASTH_CONST.apiErrorHandling(err);
        return throwError(err); 
      })
    )  	
  } 

  getItemLabel(item: any) {
    return item.ptFirstName + ' (' + item.userID+ '-'+ item.ptMobileNo + ')'
  }
}
