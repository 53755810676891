import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-preview-gallery-image',
  templateUrl: './preview-gallery-image.page.html',
  styleUrls: ['./preview-gallery-image.page.scss'],
})
export class PreviewGalleryImagePage implements OnInit {
  @Input() imageDetail;

  constructor( public modalCtrl: ModalController) { }

  ngOnInit() {
  }

  cancelUploadImage() {
    this.modalCtrl.dismiss("No");
  }

  confirmUploadImage() {
    this.modalCtrl.dismiss("Yes");
  }

}
