export const environment = {
  production: true,
  firebaseConfig: {
  apiKey: "AIzaSyAjNZDNJiDWa7E3ADeMZewHksNLA4qz4-c",
  authDomain: "swasth-plus.firebaseapp.com",
  databaseURL: "https://swasth-plus-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "swasth-plus",
  storageBucket: "swasth-plus.appspot.com",
  messagingSenderId: "75257375636",
  appId: "1:75257375636:web:6b7fc8d17d8775111c3d81",
  measurementId: "G-7FZDSD55K6"
  }
};
